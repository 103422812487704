import Trix from 'trix'

// Underline is not supported by Trix by default.
// https://github.com/basecamp/trix/blob/1.3.1/src/trix/config/text_attributes.coffee
Trix.config.textAttributes.customUnderline = {
  inheritable: true,
  parser: (element) => {
    return window.getComputedStyle(element).textDecoration === 'underline'
  },
  tagName: 'u',
}

// Custom font color attribute heavily inspired by what Basecamp/Hey uses:
// https://github.com/basecamp/trix/issues/985#issuecomment-1745005992
Trix.config.textAttributes.customFontColor = {
  inheritable: true,
  parser: (element) => {
    while (element && element.tagName !== 'TRIX-EDITOR') {
      const color = element.style['color']
      if (['blue', 'green', 'red'].includes(color)) {
        return color
      }
      element = element.parentElement
    }
  },
  styleProperty: 'color',
}

// This is a custom attribute that should get the text back to the "default"
// block type / font size. The existing `default` attribute didn't work for me.
Trix.config.blockAttributes.customBlockTypeDefault = {
  breakOnReturn: true,
  exclusive: true,
  group: false,
  tagName: 'div',
}

// This custom `h1` definition plays nicer with our controls
// than the existing `heading1` attribute.
Trix.config.blockAttributes.customBlockTypeHeading1 = {
  breakOnReturn: true,
  exclusive: true,
  group: false,
  tagName: 'h1',
}

Trix.config.blockAttributes.customBlockTypeHeading2 = {
  breakOnReturn: true,
  exclusive: true,
  group: false,
  tagName: 'h2',
}

Trix.config.blockAttributes.customBlockTypeHeading3 = {
  breakOnReturn: true,
  exclusive: true,
  group: false,
  tagName: 'h3',
}

const buildToolbarButton = (dataAttribute, iconClass) => `
  <button class="btn btn-default" ${dataAttribute} tabindex="-1" type="button">
    <i class="${iconClass}"></i>
  </button>
`

const buildToolbarDropdownMenu = (iconClass, dropdownMenuItems) => `
  <div class="btn-group">
    <button class="btn btn-default" data-toggle="dropdown" tabindex="-1" type="button">
      <i class="${iconClass}"></i>
      <i class="icon--chevron-down-small"></i>
    </button>
    <ul class="dropdown-menu">
      ${dropdownMenuItems}
    </ul>
  </div>
`

// Override the default toolbar to customize the toolbar controls as needed.
// Most things are changed greatly to remove unneeded buttons,
// use our styling, and add new custom buttons.
// Source: https://github.com/basecamp/trix/blob/1.3.1/src/trix/config/toolbar.coffee
Trix.config.toolbar.getDefaultHTML = () => `
  <div class="buttons btn-group toolbar-rich-editor">
    ${buildToolbarButton('data-trix-action="undo"', 'icon--undo')}
    ${buildToolbarButton('data-trix-action="redo"', 'icon--redo')}
    ${buildToolbarDropdownMenu(
      'icon--fontsize',
      `
      <li><a data-trix-attribute="customBlockTypeDefault">normal text</a></li>
      <li><a data-trix-attribute="customBlockTypeHeading1">h1</a></li>
      <li><a data-trix-attribute="customBlockTypeHeading2">h2</a></li>
      <li><a data-trix-attribute="customBlockTypeHeading3">h3</a></li>
      `,
    )}
    ${buildToolbarDropdownMenu(
      'icon--paint-brush',
      `
      <li><a data-trix-action="customColorBlack">black</a></li>
      <li><a data-trix-action="customColorRed">red</a></li>
      <li><a data-trix-action="customColorGreen">green</a></li>
      <li><a data-trix-action="customColorBlue">blue</a></li>
      `,
    )}
    ${buildToolbarButton('data-trix-attribute="bold"', 'icon--bold')}
    ${buildToolbarButton('data-trix-attribute="italic"', 'icon--italic')}
    ${buildToolbarButton('data-trix-attribute="customUnderline"', 'icon--underline')}
    ${buildToolbarButton('data-trix-attribute="href" data-trix-action="link"', 'icon--link')}
    ${buildToolbarButton('data-trix-attribute="quote"', 'icon--comments')}
    ${buildToolbarButton('data-trix-attribute="number"', 'icon--ol')}
    ${buildToolbarButton('data-trix-attribute="bullet"', 'icon--ul')}
    ${buildToolbarButton('data-trix-action="increaseNestingLevel"', 'icon--indent')}
    ${buildToolbarButton('data-trix-action="decreaseNestingLevel"', 'icon--outdent')}
  </div>

  <div class="dialogs" data-trix-dialogs>
    <div data-trix-dialog="href" data-trix-dialog-attribute="href">
      <div class="input-group dialogs__add-link">
        <div class="input-group-addon">
          <i class="icon--link"></i>
        </div>
        <input class="form-control" data-trix-input name="href">
        <a class="input-group-addon btn btn-default" data-trix-method="setAttribute">
          <i class="icon--check"></i>
        </a>
        <a class="input-group-addon btn btn-default" data-trix-method="removeAttribute">
          <i class="icon--close"></i>
        </a>
      </div>
    </div>
  </div>
`

// Add hook to define custom Trix actions.
document.addEventListener('trix-initialize', () => {
  const buildCustomColorAction = (color) => ({
    // `perform` needs to use `function` to edit the current editor instance.
    perform: function () {
      if (color === 'black') {
        // Applying black can just remove the custom color style
        // because it is the default font color.
        this.editor.deactivateAttribute('customFontColor')
      } else {
        this.editor.activateAttribute('customFontColor', color)
      }
    },
    // The custom color action is always available.
    test: () => true,
  })

  // We add an action for each custom color option in the toolbar.
  // The editor controller actions are shared across each editor in the DOM,
  // so adding it to the first found instance is enough.
  Object.assign(document.querySelector('trix-editor').editorController.actions, {
    customColorBlack: buildCustomColorAction('black'),
    customColorRed: buildCustomColorAction('red'),
    customColorGreen: buildCustomColorAction('green'),
    customColorBlue: buildCustomColorAction('blue'),
  })
})

// Prevent file attachments.
document.addEventListener('trix-file-accept', (event) => {
  event.preventDefault()
})
